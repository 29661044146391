import React from "react";
import logo from "../../images/logo_cavali.svg";
import exit from "../../images/exit.svg";
import Modal from "react-modal";
import "./Header.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px"
  },
};

export default function Header({ existFile }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const goBack = () => {
    closeModal();
    const url = window.location.href;

    window.sessionStorage.removeItem('searched');
    window.location.href = url.split('&access-code')[0];
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <header>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="app-title d-none d-md-block">
          <span>
            Portal de Autenticación de Constancias de Inscripción y Titularidad
          </span>

          {existFile &&
            <button className="back" onClick={() => setIsOpen(true)}>
              <img src={exit} alt="back" />
            </button>
          }
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-wrapper">
            <img src={logo} alt="background" />
            <button onClick={closeModal} className="close-btn">X</button>

            <div className="modal-body">
              <p>
                ¿Está seguro de salir del Portal de Autenticación de Constancias
                de Inscripción y Titularidad?
              </p>
              <button className="btn-blue" onClick={() => goBack()}>ACEPTAR</button>
            </div>
          </div>
        </Modal>
      </header>
    </>
  );
}
