import React from "react";

export default function DocumentViewer({ file: { fileBytes, filename } }) {

  const fileBase64 = React.useMemo(() => `data:application/pdf;base64,${fileBytes}`, [fileBytes]);

  return (
    <div className="pl-0 pl-md-5">
      <div
        className="col-12 col-md-9"
        style={{ background: 'linear-gradient(180deg, rgba(236, 236, 236, 0) 0%, #ECECEC 100%)' }}
      >
        <iframe src={fileBase64} style={{ height: '800px', width: '100%' }} frameborder="0"></iframe>

        <div className="p-4">
          <a className="btn-blue w-80 d-block m-auto" download={filename} href={fileBase64}>DESCARGAR</a>
        </div>
      </div>
    </div>
  )
}