import React from "react";
import Layout from "../../components/Layout/Layout";
import useDigitInput from "react-digit-input";
import "./FindDocument.scss";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import DocumentViewer from "../../components/DocumentViewer/DocumentViewer";

export default function FindDocument() {
  const history = useHistory();
  const url = new URL(window.location.href);
  const securityCode = url.searchParams.get("security-code");
  const accessCode = url.searchParams.get("access-code");

  const [file, setFile] = React.useState('');
  const [value, onChange] = React.useState(accessCode || "");
  const [recaptchaValue, setRecaptchaValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrrorMessage] = React.useState("");
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 5,
    value,
    onChange,
  });

  const isInvalid = React.useMemo(
    () => !(value && value.split(' ').join('').length === 5 && recaptchaValue),
    [value, recaptchaValue]
  );

  const disabledButton = React.useMemo(
    () => loading || isInvalid,
    [loading, isInvalid]
  );

  const ReCAPTCHAHandle = React.useCallback(
    (value) => {
      if (value) {
        setRecaptchaValue(value);
      }
    },
    [setRecaptchaValue]
  );
  const findDocument = React.useCallback(() => {
    setLoading(true);
    const headers = { "x-api-key": process.env.REACT_APP_X_API_KEY };
    const apiUrl = `${process.env.REACT_APP_API_URL}/document?security-code=${securityCode}&access-code=${value || accessCode}`;

    axios.get(apiUrl, { headers }).then((res) => {
      setLoading(false);
      const { data } = res;
      const { messageCore, object, filename } = data.body;

      if (object) {
        setFile({ fileBytes: object, filename });
        window.sessionStorage.setItem('searched', 'true');
      } else {
        setErrrorMessage(messageCore);
        setRecaptchaValue('')
      }
    });
  }, [securityCode, accessCode, value, history, setFile, setRecaptchaValue]);

  React.useEffect(() => {
    const searched = window.sessionStorage.getItem('searched');

    if (searched) {
      findDocument();
    }
  }, [findDocument])

  return (
    <>
      <Layout existFile={Boolean(file)}>
        {loading ? (
          <div className="loading">
            <p>Cargando...</p>
          </div>
        ) : (
          <>
            {securityCode ? (
              <>
                {Boolean(file) ? (
                  <DocumentViewer file={file} />
                ) : (
                  <div className="find-document-wrapper">
                    <div className="find-document-form">
                      <div className="title">
                        <span>Búsqueda de Constancia</span>
                      </div>

                      <div className="d-flex justify-content-center digits">
                        <div className="d-md-flex">
                          <span className="d-block mb-3">Código de Verificación:</span>
                          <div className="input-group ">
                            {[0, 1, 2, 3, 4].map((key) => (
                              <input
                                key={key}
                                inputMode="decimal"
                                autoFocus={key === 0}
                                {...digits[key]}
                              />
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-5">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_GOOGLE_CLIENT_KEY}
                          onChange={ReCAPTCHAHandle}
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <button
                          className="btn-blue"
                          disabled={disabledButton}
                          onClick={findDocument}
                        >
                          {loading ? "Buscando..." : "ENTRAR"}
                        </button>
                      </div>
                      {errorMessage && (
                        <div className="row">
                          <div className="col-12 col-md-6 offset-0 offset-md-3 mt-5 text-center">
                            <div className="alert alert-danger" role="alert">
                              <span>{errorMessage}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="alert alert-danger" role="alert">
                  <strong>Debe ingresar con una URL válida</strong>
                </div>
              </div>
            )}
          </>
        )}
      </Layout>
    </>
  );
}
