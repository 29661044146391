import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import FindDocument from "./views/FindDocument/FindDocument";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/find-document" />}
        />
        <Route exact path="/find-document" component={FindDocument} />
      </Switch>
    </Router>
  );
}