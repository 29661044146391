import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Layout.scss";

export default function Layout({ existFile, children }) {
  return (
    <>
      <Header existFile={existFile} />
        <div className="content">
          {children}
        </div>
      <Footer />
    </>
  )
}