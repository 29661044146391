import React from "react";
import './Footer.scss'

export default function Footer() {
  return (
    <>
      <footer>
        <p className="m-0">
          COPYRIGHT 2021 - CAVALI / PROTECCION DE DATOS <br />
          AVENIDA JORGE BASADRE 347, SAN ISIDRO, LIMA, PERÚ. CENTRAL TELEFÓNICA : (511) 311-2200
        </p>
      </footer>
    </>
  )
}